import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to fix the React memory leak warning",
  "description": "If you've ever worked with React function components and the useEffect hook, \nthere's a high probability that you've faced this warning.\n",
  "meta": {
    "description": "How to fix React memory leak warnings with useEffect cleanup functions"
  },
  "promoted": true,
  "publishedAt": "2021-04-14",
  "published": true,
  "tags": ["React", "JavaScript", "Typescript"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`If you've ever worked with React function components and the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-effect.html"
      }}>{`useEffect`}</a>{` hook, it's almost impossible that you've never faced this warning:`}</p>
    <pre><code parentName="pre" {...{}}>{`Warning: Can't perform a React state update on an unmounted component.
This is a no-op, but it indicates a memory leak in your application.
To fix, cancel all subscriptions and asynchronous tasks in a useEffect
cleanup function.
`}</code></pre>
    <p>{`This is the warning I'm referring to as `}<em parentName="p">{`the`}</em>{` React memory leak warning because it is very easy to trigger and hard to get rid of if you don't understand what's happening.`}</p>
    <h2>{`Explaining the warning`}</h2>
    <p>{`There are 4 important concepts here:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Can't perform a React state update`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`on an unmounted component.`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`To fix, cancel all subscriptions and asynchronous tasks`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`in a useEffect cleanup function.`}</inlineCode></li>
    </ul>
    <p>{`I won't explain what a memory leak is, instead I'll encourage you to read what is my go-to article about `}<a parentName="p" {...{
        "href": "https://blog.sessionstack.com/how-javascript-works-memory-management-how-to-handle-4-common-memory-leaks-3f28b94cfbec"
      }}>{`memory management in Javascript`}</a>{`.`}</p>
    <h3>{`What is a `}<em parentName="h3">{`state update`}</em>{`?`}</h3>
    <p>{`Given the following state initialization:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const [isVisible, setIsVisible] = useState(true);
`}</code></pre>
    <p>{`A state update would be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`setIsVisible(false);
`}</code></pre>
    <h3>{`What is `}<em parentName="h3">{`an unmounted component`}</em>{`?`}</h3>
    <p>{`A component is `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-component.html#unmounting"
      }}>{`unmounted`}</a>{` when it is removed from the DOM. It is the final step of a React component's life cycle.`}</p>
    <h3>{`What are `}<em parentName="h3">{`subscriptions`}</em>{` and `}<em parentName="h3">{`asynchronous tasks`}</em>{`?`}</h3>
    <p>{`Asynchronous tasks are callbacks sent to the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#queue"
      }}><em parentName="a">{`queue of callbacks`}</em></a>{` of the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=8aGhZQkoFbQ"
      }}>{`event loop`}</a>{`. They are asynchronous because they won't be executed until some conditions are met.`}</p>
    <p>{`Any mechanism that can add a callback to the `}<em parentName="p">{`queue of callbacks`}</em>{`, thereby deferring its execution until the fulfillment of a condition, can be considered as a `}<em parentName="p">{`subscription`}</em>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
          }}>{`Promises`}</a>{` when fulfilled or rejected`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout"
          }}><inlineCode parentName="a">{`setTimeout`}</inlineCode></a>{` and `}<a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setInterval"
          }}><inlineCode parentName="a">{`setInterval`}</inlineCode></a>{` when a certain time has elapsed`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events"
          }}>{`Events`}</a>{` when the event occurs`}</p>
      </li>
    </ul>
    <p>{`I've skipped `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/setImmediate"
      }}><inlineCode parentName="a">{`setImmediate`}</inlineCode></a>{` since it's not a web standard, and I'm simplifying things by referring to a unique `}<em parentName="p">{`queue of callbacks`}</em>{` when there's in fact multiple queues with different levels of priority.`}</p>
    <h4>{`Case 1 - Asynchronous task in a Promise handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`someAsyncFunction().then(() => {
  // Here is the asynchronous task.
});
`}</code></pre>
    <p><inlineCode parentName="p">{`someAsyncFunction()`}</inlineCode>{` returns a `}<inlineCode parentName="p">{`Promise`}</inlineCode>{` we can `}<em parentName="p">{`subscribe`}</em>{` to by calling the `}<inlineCode parentName="p">{`then()`}</inlineCode>{` method with a callback function as the `}<em parentName="p">{`task`}</em>{` to execute when `}<inlineCode parentName="p">{`someAsyncFunction()`}</inlineCode>{` resolves.`}</p>
    <h4>{`Case 2 - Asynchronous task in a `}<inlineCode parentName="h4">{`setTimeout`}</inlineCode>{` handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`setTimeout(() => {
  // Here is the asynchronous task.
});
`}</code></pre>
    <p><inlineCode parentName="p">{`setTimeout`}</inlineCode>{` is usually called with a delay as a second argument, but `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop#zero_delays"
      }}>{`when left empty`}</a>{`, the event handler will be executed as soon as the event loop starts to process the `}<em parentName="p">{`queue of callbacks`}</em>{`, but it is still asynchronous and has a significant chance to be executed after the component has been unmounted.`}</p>
    <h4>{`Case 3 - Asynchronous task in an event handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`Dimensions.addEventListener('change', ({ screen }) => {
  // Here is the asynchronous task.
});
`}</code></pre>
    <p>{`Subscribing to an event is done by adding an event listener and passing a callback function to the listener.`}</p>
    <p>{`Until the event listener is removed or the event emitter is destroyed, the callback function will be added to the `}<em parentName="p">{`queue of callbacks`}</em>{` on every event occurrence.`}</p>
    <h3>{`Asynchronous tasks are side effects`}</h3>
    <p>{`In React functional components any side effects such as data fetching or event handling `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-overview.html#effect-hook"
      }}>{`should be done inside a useEffect`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  someAsyncFunction().then(() => {
    // Here is an asynchronous task.
  });

  Dimensions.addEventListener('change', ({ screen }) => {
    // There is another asynchronous task.
  });
}, []);
`}</code></pre>
    <h3>{`What is a `}<em parentName="h3">{`useEffect cleanup function`}</em>{`?`}</h3>
    <p><a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-effect.html#effects-with-cleanup"
      }}>{`Every effect may return a function that cleans up after it`}</a>{`. This function is called when the component is unmounted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  return () => {
    // This is the cleanup function
  };
}, []);
`}</code></pre>
    <h2>{`What is wrong?`}</h2>
    <p>{`React is telling us to stop trying to update the state of a component that has been deleted.`}</p>
    <h4>{`Case 1 - Asynchronous task in a Promise handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  someAsyncFunction().then(() => {
    setIsVisible(false);
  });
}, []);
`}</code></pre>
    <p>{`Because we've subscribed to a Promise, there's a pending callback, waiting for the Promise to settle, regardless of whether it has been fulfilled or rejected.`}</p>
    <p>{`If the React component is unmounted before the Promise completion, the pending callback stays in the `}<em parentName="p">{`callback queue`}</em>{` anyway.`}</p>
    <p>{`And once the Promise has settled, it will try to update the state of a component that doesn't exist anymore.`}</p>
    <h4>{`Case 2 - Asynchronous task in a `}<inlineCode parentName="h4">{`setTimeout`}</inlineCode>{` handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  setTimeout(() => {
    setIsVisible(false);
  }, 5000);
}, []);
`}</code></pre>
    <p>{`This code is close to the previous case except that the condition for the callback to be executed is to wait 5000ms.`}</p>
    <p>{`If the React component is unmounted before this amount of time, it will also try to update the state of a component that doesn't exist anymore.`}</p>
    <h4>{`Case 3 - Asynchronous task in an event handler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  Dimensions.addEventListener('change', ({ screen }) => {
    setDimensions(screen);
  });
}, []);
`}</code></pre>
    <p>{`Attaching handlers to events is different from the previous cases because events can occur multiple times and therefore can trigger the same callback multiple times.`}</p>
    <p>{`If the event emitter we've bound an event handler is not destroyed when the React component is unmounted, it still exists and will be executed on every event occurrence.`}</p>
    <p>{`In the above example, the event handler is bound to a global variable `}<inlineCode parentName="p">{`Dimensions`}</inlineCode>{`, the event emitter, which exists outside of the scope of the component.`}</p>
    <p>{`Therefore, the event handler is not unbound or garbage collected when the component is unmounted, and the event emitter might trigger the callback in the future even though the component doesn't exist anymore.`}</p>
    <h2>{`Fixing the problem`}</h2>
    <h4>{`Case 1 - Asynchronous task in a Promise handler`}</h4>
    <p>{`Since it is not possible to cancel a Promise the solution is to prevent the `}<inlineCode parentName="p">{`setIsVisible`}</inlineCode>{` function to be called if the component has been unmounted.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const [isVisible, setIsVisible] = useState(true);

useEffect(() => {
  let cancel = false;

  someAsyncFunction().then(() => {
    if (cancel) return;
    setIsVisible(false);
  });

  return () => {
    cancel = true;
  };
}, []);
`}</code></pre>
    <p>{`By leveraging `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Closures#lexical_scoping"
      }}>{`lexical scoping`}</a>{`, we can share a variable between the callback function and the cleanup function.`}</p>
    <p>{`We use the cleanup function to modify the `}<inlineCode parentName="p">{`cancel`}</inlineCode>{` variable and trigger an `}<a parentName="p" {...{
        "href": "https://dev.to/jpswade/return-early-12o5"
      }}>{`early return`}</a>{` in the callback function to prevent the state update.`}</p>
    <h4>{`Case 2 - Asynchronous task in a `}<inlineCode parentName="h4">{`setTimeout`}</inlineCode>{` handler`}</h4>
    <p>{`To remove a callback bound to a timer, remove the timer:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`useEffect(() => {
  const timer = setTimeout(() => {
    setIsVisible(false);
  });
  return () => {
    clearTimeout(timer);
  };
}, []);
`}</code></pre>
    <h4>{`Case 3 - Asynchronous task in an event handler`}</h4>
    <p>{`To cancel a subscription to an event, remove the event handler:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const onChange = ({ screen }) => {
  setDimensions(screen);
};

useEffect(() => {
  Dimensions.addEventListener('change', onChange);
  return () => {
    Dimensions.removeEventListener('change', onChange);
  };
}, []);
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Global variables are never garbage collected so don't forget to remove event handlers manually if the event emitter is stored in a global variable.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Remove any event handlers bound to event emitters that might not be removed when a component is unmounted.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Promises cannot be cancelled but you can use lexical scoping to change the behavior of the callback from the `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` cleanup function by triggering an early return or `}<a parentName="p" {...{
            "href": "https://dev.to/witaylor/short-circuit-conditionals-in-javascript-54i2"
          }}>{`short-circuiting`}</a>{` the state update.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Try to avoid timers, if you can't, be sure to always cancel them with `}<inlineCode parentName="p">{`clearTimeout`}</inlineCode>{` or `}<inlineCode parentName="p">{`clearInterval`}</inlineCode>{`.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      